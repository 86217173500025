import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoLogoLinkedin } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import { cn } from "../utils/utils";
import Logo from "./Logo";
import ScheduleBtn from "./ScheduleBtn";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseEnter = index => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const options = [
    { label: "Accounts Payable", path: "/feature/accounts-payble" },
    { label: "Vendor Management", path: "/feature/vendor-management" },
    { label: "More Features", path: "/feature" },
    // { label: "ITC Management", path: "/feature/itc-management" },
  ];

  return (
    <div
      className={cn(
        "fixed left-1/2 top-0 z-50 flex w-full max-w-screen-3xl -translate-x-1/2 items-center justify-between p-2 shadow-sm",
        !isMenuOpen ? "bg-primary/5 backdrop-blur" : "bg-white",
      )}
    >
      <Logo />
      <div className="hidden flex-1 items-center justify-center gap-16 md:flex">
        <Link

          className="cursor-pointer leading-4 text-green-800 transition hover:text-green-950"
          to="/story"
        >
          Our Story
        </Link>
        <div className="group relative">
          {/* Main Link */}
          <Link
            to=""
            className="flex cursor-pointer items-center text-green-800"
          >
            Features
            <RiArrowDropDownLine />
          </Link>

          {/* Dropdown */}
          <div
            className="absolute left-1/2 top-full z-10 hidden w-48 -translate-x-1/2 rounded-md border p-3 shadow-sm group-hover:block bg-[#ffffffba] backdrop-blur-md"
          >
            <ul className="flex flex-col text-left">
              {options.map((option, index) => (
                <li
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  className={`block w-full px-0 py-2 text-green-800 transition-all duration-300 ease-in-out ${activeIndex === index ? "scale-105 text-green-900" : "hover:text-green-900"
                    }`}
                >
                  <Link to={option.path}>{option.label}</Link>
                </li>
              ))}
            </ul>
          </div>

        </div>
        <Link
          to="/blogpage"
          className="cursor-pointer text-green-800 hover:text-green-950"
        >
          Blogs
        </Link>
      </div>

      {/* Schedule a Demo for large screens */}
      <div className="hidden md:block">
        <ScheduleBtn />
      </div>

      {/* Hamburger Menu Icon for small screens */}
      <div className="flex items-center text-[#144944] md:hidden">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <AiOutlineClose className="text-green-900" size={24} />
          ) : (
            <GiHamburgerMenu size={24} />
          )}
        </button>
      </div>

      {isMenuOpen && (
        <div
          className={`absolute left-0 top-[3.6rem] z-50 flex h-[100vh] w-full flex-col justify-between gap-10 bg-white/30 p-4 text-white backdrop-blur-md ${isMenuOpen ? "animate-uparniche" : "animate-nicheupar"}`}
          style={{ height: "calc(100vh - 3.6rem)" }}
        >
          <div className="grid gap-2 font-medium text-green-800">
            <Link
              to="/story"
              className="rounded-md p-4 hover:bg-primary/10 focus:bg-primary/10"
              onClick={() => setIsMenuOpen(false)}
            >
              Our Story
            </Link>
            <div className="group relative">
              {/* Main Link */}
              <Link
                to=""
                className="flex cursor-pointer p-4 items-center text-green-800"
              >
                Features
                <RiArrowDropDownLine />
              </Link>

              {/* Dropdown */}
              <div className="absolute left-28 top-full z-10 hidden w-48 -translate-x-1/2 rounded-md border-solid bg-[#ffffffa4] p-3 shadow-xl backdrop-blur-2xl group-hover:block">
                <ul className="flex flex-col text-left">
                  {options.map((option, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className={`block w-full px-0 py-2 text-green-800 transition-all duration-300 ease-in-out ${activeIndex === index
                        ? "scale-105 text-green-900"
                        : "hover:text-green-900"
                        }`}
                    >
                      <Link to={option.path}>{option.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Link
              to="/blogpage"
              className="rounded-md p-4 hover:bg-primary/10 focus:bg-primary/10"
              onClick={() => setIsMenuOpen(false)}
            >
              Blogs
            </Link>
          </div>

          <div className="space-y-10">
            <div className="flex flex-col gap-5">
              <h5 className="rounded-lg text-sm text-green-900">
                Transform Your Financial Processes <br />
                with Neo CFO
              </h5>
              <ScheduleBtn isPrimary />
            </div>

            <div className="flex items-center gap-3 border-t-2 border-primary/20 pb-10 pt-6">
              <Link to="#">
                <IoLogoLinkedin className="cursor-pointer text-2xl text-green-800" />
              </Link>
              <Link to="#">
                <FaInstagram className="cursor-pointer text-2xl text-green-800" />
              </Link>
              <Link to="#">
                <FaSquareXTwitter className="cursor-pointer text-2xl text-green-800" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
