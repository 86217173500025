import Carousel from "react-multi-carousel";

// import useIsMobile from "../hooks/isMobile";

const featureResponsive = {
  desktop: { breakpoint: { max: 1920, min: 1024 }, items: 1, slidesToSlide: 1 },
  tablet: { breakpoint: { max: 1024, min: 512 }, items: 1, slidesToSlide: 1 },
  mobile: { breakpoint: { max: 512, min: 0 }, items: 1, slidesToSlide: 1 },
};

const cardData = [
  {
    imgSrc: "/billmanagement02.jpg",
    title: "Receipts, Handled",
    description: "Collect and parse invoices from multiple channels, including WhatsApp, Slack, and email, extracting relevant details effortlessly.",
  },
  {
    imgSrc: "/billmanagement2.jpg",
    title: "Invoices Processed",
    description: "Collect and parse invoices from multiple channels, including WhatsApp, Slack, and email, extracting relevant details effortlessly.",
  },
  {
    imgSrc: "/billmanagement3.jpg",
    title: "Payments Managed",
    description: "Collect and parse invoices from multiple channels, including WhatsApp, Slack, and email, extracting relevant details effortlessly.",
  },
];

export default function AccountpaybleCards() {
  return (
    <div className="bg-[#F9F9F9] pt-40  pb-20 p-8">
      <p className="text-3xl text-[#3B3B3B] md:text-3xl md:p-10 md:pb-2 mb-5 text-center">
      AI detecting  invoice details
       
      </p>

      {/* Carousel Wrapper */}
      <Carousel
        responsive={featureResponsive}
        infinite
        autoPlay
        autoPlaySpeed={2000}
        keyBoardControl
        transitionDuration={500}
  
        containerClass="carousel-container"
        itemClass="px-4"
      >
        {cardData.map((card, index) => (
          <div key={index} className="relative overflow-hidden rounded-lg p-4 md:p-12 transform transition-transform duration-300 ease-in-out hover:scale-[102%] ">
            <img
              src={card.imgSrc}
              alt={card.title}
              className="object-fit w-[90rem] md:object-fit h-[30rem]  md:h-[40rem] md:w-[60rem] mx-auto p-6 shadow-xl shadow-[#706f6f63] rounded-3xl transition-opacity duration-300 ease-in-out hover:opacity-80"
            />

          </div>
        ))}
      </Carousel>
    </div>
  );
}
