export const cardData = [
  {
    id: 1,
    backgroundImage: "/blog1.jpg",
    category: ["Accounting","Finance Stack","Expense Management"],
    title: "Accounts Payable Invoice Scanning Automation with Neo CFO",
    readTime: "5 min read",
  },
  {
    id: 2,
    backgroundImage: "/Blog2.webp",
    category: ["AI Insights"],
    title: "The Future of Finance is AI: How to Leverage it Today",
    readTime: "8 min read",
  },
  {
    id: 3,
    backgroundImage: "/blog3.png",
    category: ["Finance Stack", "Accounting", "Expense Management"],
    title: "How AP Automation Transforms Annual Audits",
    readTime: "5 min read",
  },
  {
    id: 4,
    backgroundImage: "/blog4.png",
    category: ["Accounting","Expense Management","Finance Stack"],
    title: "Revolutionize Your Accounts Payable: Seamless Automation with Zoho Books and Neo CFO ",
    readTime: "5 min read",
  },
  {
    id: 5,
    backgroundImage: "/blog5.jpg",
    category: ["Accounting","Expense Management","Finance Stack"],
    title: "Essential KPIs to Track for Accounts Payable Teams: Optimizing Financial Performance ",
    readTime: "5 min read",
  },
  ];
  

  export const homeBlogData = [
    {
      id: 1,
      image: "ExploreMore1.jpeg",
      text: "Accounts Payable Invoice Scanning Automation with Neo CFO",
      readTime: 5,
    },
    {
      id: 2,
      image: "Blog2.webp",
      text: "The Future of Finance is AI: How to Leverage it Today",
      readTime: 5,
    },
    {
      id: 3,
      image: "ExploreMore3.jpeg",
      text: "How AP Automation Transforms Annual Audits",
      readTime: 5,
    },

  ];


 export const jsonData = {
      sections: [
        {
          id: "section-1",
          // title: "The Future of Finance is AI: How to Leverage it Today",
          content: [
            // "2024 was the year of AI – everyone from presidential candidates to incoming freshmen has been using AI to make their lives better.",
            "Matt O’Brien and Sarah Parvini recently published an article in the Associated Press called 'In 2024, artificial intelligence was all about putting AI tools to work.' However, that has not broadly been the case for finance teams. Within most finance departments, AI remains a strategic priority but has not yet been put to work within their organizations."
          ]
        },
        {
          id: "section-2",
          title: "",
  
          content: [
            {
              heading: "The Potential of AI in Finance",
              details: [
                "What's clear to CFOs is that AI isn't just another trend – it's poised to fundamentally transform how work gets done. Over and over finance leaders have seen the impact that AI has had on day-to-day workflows by taking on the most repetitive and mundane tasks and streamlining those to seconds. Marketing teams are getting more creative with copywriting & design, support tickets are being answered in seconds, and engineering teams are writing 2x the amount of code."
              ]
            }
          ]
        },
        {
          id: "section-4",
          title: "Key Areas Where AI is Already Making an Impact",
          content: [
            {
              heading: "The Close Process: Closing the Books Faster Than Ever",
              details: [
                "Traditionally, closing the books is a time-consuming, manual process, often involving countless hours of reconciliation, data entry, and review. Studies show that the average time to close the books can range from several days to several weeks.",
                "AI is already automating key aspects of the close process across:",
                " • Automate Reconciliation: AI can match transactions across different systems of records, identifying discrepancies and automatically reconciling accounts, significantly reducing manual effort."
              ]
            }
          ]
        },
        {
          id: "section-5",
          title: "Financial Reporting: From Days to Minutes",
          content: [
            {
              heading: "AI is already automating core reporting workflows:",
              details: [
                "Creating financial reports is one of the least favorite tasks that corporate finance teams have to perform. It involves manually collecting data from various sources, consolidating it into spreadsheets, and then formatting it into presentable reports.",
                "AI-powered reporting enables teams to create comprehensive monthly reports in a fraction of the time."
              ]
            }
          ]
        },
        {
          id: "section-6",
          title: "Benefits of AI in Corporate Finance Teams",
          content: [
            {
              benefit: "Fewer Mistakes",
              details: [
                "By automating manual data entry, calculations, and reconciliation processes, AI significantly reduces the risk of human error, leading to more accurate financial records and reporting."
              ]
            }
          ]
        },
      ]
    };
  

    export const blog1sections = [
      {
        title: "Understanding Invoice Scanning in Accounts Payable",
        paragraphs: [
          "In today's digital age, organizations are increasingly turning to invoice scanning solutions to revolutionize their accounts payable processes.",
          "Understanding the fundamentals of invoice scanning and its integration with accounts payable operations is crucial for businesses aiming to optimize their financial workflows and achieve greater efficiency.",
        ],
      },
      {
        title: "Demystifying Invoice Scanning: A Comprehensive Overview",
        paragraphs: [
          "Invoice scanning represents the cornerstone of modern accounts payable automation, transforming physical and digital invoices into structured, actionable data.",
          "At its core, accounts payable invoice scanning involves capturing invoice documents through scanning devices or digital uploads, converting them into machine-readable formats, and extracting pertinent information for processing.",
        ],
      },
      {
        title: "Traditional Audit Challenges in Indian Business Landscape",
        paragraphs: [
          "Historically, Indian businesses faced numerous challenges in managing their audit processes, especially in accounts payable operations. Manual documentation, physical storage requirements, and time-consuming verification processes created significant bottlenecks during annual audits. Some key challenges included:",
          "• Extensive paper trail management across multiple locations",
          "• High risk of human errors in data entry and reconciliation",
          "• Delayed vendor payments due to lengthy approval cycles",
          "• Limited visibility into payment statuses and audit trails",
          "The traditional approach often resulted in audit delays, compliance issues, and increased operational costs, prompting businesses to seek more efficient solutions.",
        ],
      },
      {
        title: "Essential Components of the AP Process",
        listItems: [
          {
            heading: "Invoice Receipt and Capture",
            description:
              "The modern accounts payable process begins with sophisticated multi-channel invoice reception, including email automation, dedicated vendor portals, and intelligent document scanning capabilities. Leading solutions like cfo can process invoices from any source while maintaining data integrity and ensuring no document is overlooked in the workflow.",
          },
          {
            heading: "Data Extraction and Validation",
            description:
              "Advanced validation engines perform comprehensive checks on extracted data, verifying everything from tax compliance to payment terms accuracy. Modern systems employ machine learning algorithms to continuously improve extraction accuracy, reducing the need for manual intervention while maintaining high standards of data quality.",
          },
          {
            heading: "Workflow Routing",
            description:
              "Intelligent workflow systems analyze invoice characteristics and organizational hierarchies to automatically direct documents to appropriate approvers. These systems adapt to your business rules while maintaining flexibility for special cases and emergency approvals, ensuring smooth processing even in complex scenarios.",
          },
        ],
      },
      {
        title: "Digital Transformation Benefits",
        paragraphs: [
          "1. Cost Reduction: Organizations implementing advanced invoice scanning solutions typically achieve 60-80% reduction in processing costs per invoice, with additional savings realized through early payment discounts and eliminated late payment penalties. These cost benefits compound over time as systems become more efficient through machine learning capabilities.",
          " 2. Time Efficiency:Modern automation reduces invoice processing times from weeks to hours, enabling organizations to handle larger invoice volumes without adding headcount. This dramatic improvement in processing speed leads to better vendor relationships and increased operational efficiency across the finance function.",
          "3. Enhanced Accuracy:  Automated data capture and validation significantly reduce error rates compared to manual entry, with leading solutions achieving accuracy rates above 95%. This improved accuracy leads to fewer payment errors, reduced reconciliation time, and better financial reporting quality.",
        ],
      },
      
      {
        title: "The Transformative Role of OCR Technology",
        paragraphs: [
          "• Intelligent Data Capture: State-of-the-art OCR algorithms leverage machine learning to adapt to various invoice formats, layouts, and styles, ensuring accurate data extraction regardless of source.",
          "    Value: These systems learn from each processed document, continuously improving their accuracy and reducing the need for manual intervention in complex scenarios.",
          "• Pattern Recognition: Advanced OCR systems employ sophisticated pattern recognition algorithms that can identify and categorize invoice elements based on historical data and learned patterns.",
        ],
      },
      {
        title: "Advanced Invoice Processing Capabilities",
        paragraphs: [
          "At the heart of cfo's invoice scanning solution lies its sophisticated OCR technology, specifically calibrated for Indian tax regulations. The platform's intelligent data extraction capabilities include:",
          "• Context-aware processing that accurately interprets GST components, TDS calculations, and RCM requirements",
          "• Multi-language support optimized for Indian regional languages and document formats",
          "• Machine learning algorithms that continuously improve recognition accuracy through pattern analysis",
          "• Real-time validation through a proprietary engine performing over 22 unique checks during processing",
          "This advanced technology ensures that every invoice, regardless of format or complexity, is processed with exceptional accuracy while maintaining compliance with Indian financial regulations.",
        ],
      },
      {
        title: "Intelligent Workflow Automation",
        paragraphs: [
          "cfo revolutionizes traditional AP workflows through:",
          "• Dynamic routing algorithms that automatically direct invoices based on amount thresholds, departments, and custom business rules",
          "• Multi-level approval hierarchies that reflect organizational structure while maintaining flexibility for exceptions",
          "• Mobile-first approval interfaces enabling on-the-go decision-making",
          "• Real-time status tracking and automated escalation procedures for pending approvals",
          "• Smart delegation rules that ensure business continuity during approver absences",
        ],
      },
      {
        // title: "Intelligent Workflow Automation",
        paragraphs: [
          "cfo revolutionizes traditional AP workflows through:",
          "• Dynamic routing algorithms that automatically direct invoices based on amount thresholds, departments, and custom business rules",
          "• Multi-level approval hierarchies that reflect organizational structure while maintaining flexibility for exceptions",
          "• Mobile-first approval interfaces enabling on-the-go decision-making",
          "• Real-time status tracking and automated escalation procedures for pending approvals",
          "• Smart delegation rules that ensure business continuity during approver absences",
        ],
      },
    ];
    
    export const blogfaqData1 = [
      {
        question: ' Q: How long does implementation take?',
        answer:
          ' Most organizations go live within 2-4 weeks, depending on system complexity.',
      },
      {
        question: 'Q: Can it handle regional languages?',
        answer:
          ' Yes, the OCR is calibrated for multiple Indian languages and tax formats.',
      },
      {
        question: 'Q: How secure is the system? ',
        answer:
          ' We offer bank-grade encryption, role-based access, and comprehensive audit trails.',
      },
    ];
    

    export const blogfaqData2 = [
      {
        question: ' Q: How do KPIs help with tax compliance?',
        answer:
          ' KPIs like GST reconciliation help ensure regulatory compliance and reduce penalty risks. ',
      },
      {
        question: 'Q:Can KPIs aid in financial planning?',
        answer:
          'Yes, by providing insights into cash flow, invoice processing, and payment schedules.',
      },
      {
        question: 'Q: How can teams measure the impact of late payments?',
        answer:
          ' By tracking late payment penalties and monitoring supplier satisfaction metrics. ',
      },
    ];