// import "../css/read.css";

import { GoArrowUpRight } from "react-icons/go";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Blog1 from "../components/Blog1";
import Blog2 from "../components/Blog2";
import Blog3 from "../components/Blog3";
import Blog4 from "../components/Blog4";
import Blog5 from "../components/Blog5";
import Footer from "../components/footer";
// import { homeBlogData } from "./contents/blog-content";
import HomeBlogCard from "../components/HomeBlogCard";


export default function Read() {
  const { blogId } = useParams();

  let blogContent;
  switch (blogId) {
    case "1":
      blogContent = <Blog1 />;
      break;
    case "2":
      blogContent = <Blog2 />;
      break;
    case "3":
      blogContent = <Blog3 />;
      break;
    case "4":
      blogContent = <Blog4 />;
      break;
    case "5":
      blogContent = <Blog5 />;
      break;
    default:
      blogContent = <div>Blog not found</div>;
      break;
  }
  return (
    <>
      <div></div>
      {blogContent}
      <div>
        <div className="md:p-36 p-8">
          <div className="justify-left mt-10 flex md:justify-between">
            <p className="mb-14 pl-1 text-3xl text-[#144944]">Explore More</p>
            <div>
              <h1 className="w-25 hidden items-center justify-center rounded-2xl bg-[#f0f0f0] px-4 py-2 text-lg text-[#144944] md:flex">
                <Link
                  to="/blogpage"
                  className="flex items-center text-lg"
                >
                  <span className="mr-2">View more</span>
                </Link>
                <GoArrowUpRight />
              </h1>
            </div>
          </div>
          <div className="w-[100%]">
            <HomeBlogCard />
          </div>
          <h1 className=" mt-5 md:hidden items-center inline-block justify-center rounded-2xl bg-[#f0f0f0] px-4 py-2 text-lg text-[#144944] ">
            <Link
              to="/blogpage"
              className="flex items-center text-lg"
            >
              <span className="mr-2">View more</span>
            <GoArrowUpRight />
            </Link>
          </h1>


        </div>
      </div>
      <div className="flex justify-center">
        <div id="demo" style={{ marginTop: "150px", height: "270px" }}>
          <h1 className="font-sans text-[#144944]">
            Transform Your Financial <br />
            Processes with Neo CFO
          </h1>
          <div className="w-full bg-red-500">
            <button
              className="flex -translate-y-4 items-center rounded-sm bg-white px-4 py-2 font-sans font-semibold text-[#144944]"
              style={{ fontSize: "12px" }}
            >
              SCHEDULE DEMO{" "}
              <GoArrowUpRight className="text-xs text-[#144944]" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-64">
        <Footer />
      </div>
    </>
  );
}
