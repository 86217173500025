import React from 'react';
import { GoArrowUpRight } from 'react-icons/go';
import { Link } from 'react-router-dom';

const ReadMoreBtn = ({ to }) => {
  return (
    <Link
      className="flex items-center justify-center gap-2.5 rounded-full bg-black/30 px-8 py-2.5 text-sm text-white backdrop-blur hover:bg-black/20"
      to={to}
    >
      Read More <GoArrowUpRight />
    </Link>
  );
};

ReadMoreBtn.propTypes = {
  to: React.string,
};

export default ReadMoreBtn;
