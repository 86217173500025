import "../css/blog.css";

import { useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";

import FadeInWrapper from "../components/FadeInWrapper";
import Footer from "../components/footer";
import { cardData } from "./contents/blog-content";
export default function Blogpage() {
  const [activeTag, setActiveTag] = useState("All");
  const [visibleLayers, setVisibleLayers] = useState({});

  const handleMouseEnter = index => {
    setVisibleLayers(prev => ({ ...prev, [index]: false }));
  };

  const handleMouseLeave = index => {
    setVisibleLayers(prev => ({ ...prev, [index]: true }));
  };

  const filteredCards =
    activeTag === "All"
      ? cardData
      : cardData.filter(data => data.category.includes(activeTag));
  return (
    <>
      <div className="max-w-screen w-full overflow-x-hidden">
        <main className="blog-section mx-auto md:p-24">
          <div className="container py-10">
            <FadeInWrapper direction="left">
            <div
              className="mx-auto flex w-full items-center justify-between md:w-auto"
              style={{ fontFamily: "Geist", font: "400", color: "#000000" }}
            >
              <h2 className="mb-1">Blogs</h2>
            </div>
            </FadeInWrapper>
            <FadeInWrapper direction="left">
            <h1
              style={{
                fontSize: "28px",
                color: "#3B3B3B",
                transform: "rotate(-0.08deg)",
                lineHeight: "1.2",
              }}
            >
              Insights & Innovations:
              <br />
              Let&apos;s Explore the{" "}
              <i
                className="text-3xl sm:text-4xl md:text-5xl lg:text-3xl"
                style={{
                  fontFamily: "Playfair Display, serif",
                  fontStyle: "italic",
                }}
              >
                Future of Finance
              </i>
            </h1>
            </FadeInWrapper>
            {/* cards with tabs */}
            <div className="mt-[5rem]">
            <FadeInWrapper direction="left">
              <div className="flex flex-wrap gap-6">
                {[
                  "All",
                  "Finance Stack",
                  "Expense Management",
                  "Accounting",
                  "AI Insights",
                ].map(tag => (
                  <button
                    key={tag}
                    className={`rounded px-5 py-2 font-sans text-sm font-semibold transition duration-200 ${activeTag === tag
                        ? "bg-[#14494433] text-green-950"
                        : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                      }`}
                    onClick={() => setActiveTag(tag)}
                  >
                    {tag}
                  </button>
                ))}
              </div>
              </FadeInWrapper>
              <FadeInWrapper direction="left">
              <div className="mt-10 grid gap-5 md:grid-cols-3">
                {filteredCards.map((data, index) => (
                  <Link key={index} to={`/read/${index + 1}`}>
                    <div
                      className="relative overflow-hidden rounded-2xl bg-white shadow-lg transition-transform duration-300 hover:scale-105"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      style={{
                        backgroundImage: `url(${data.backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "250px",
                      }}
                    >
                      <div
                        className={`duration-2000 absolute inset-0 bg-black transition-opacity ${visibleLayers[index] === false
                            ? "opacity-0"
                            : "opacity-50"
                          }`}
                      ></div>

                      <div className="absolute left-2 top-2 z-10 m-2 block rounded-full px-2 py-1 text-xs text-white">
                        {data.category.map((cat, index) => (
                          <span
                            key={index}
                            className="m-1 flex-wrap rounded-full bg-[#FFFFFF33] px-2 py-1 text-xs text-white"
                          >
                            {cat}
                          </span>
                        ))}
                      </div>
                      <div className="absolute bottom-2 left-4 right-4 z-10">
                        <h2
                          style={{ color: "#FFFFFF" }}
                          className="mb-2 text-lg font-semibold sm:text-xl lg:text-xl"
                        >
                          {data.title}
                        </h2>
                        <p
                          className="lg:text-md mb-1 text-xs sm:text-sm"
                          style={{ color: "#FFFFFF99" }}
                        >
                          {data.readTime}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              </FadeInWrapper>
            </div>

            {/* <div className="w-full sm:w-[83vw]">
              <div id="pagination" className="flex justify-between gap-2">
                <span id="" className="text-[#1449447e]">
                  Page 1 of 10
                </span>
                <button id="pagination-button">
                  <i
                    style={{
                      border: "1px solid #14494433",
                      padding: "11px",
                      color: "gray",
                      borderRadius: "5px",
                    }}
                    className="fa-solid fa-angles-left"
                  ></i>
                </button>
                <button id="">
                  <i
                    style={{
                      border: "1px solid #14494433",
                      padding: "11px",
                      color: "gray",
                      borderRadius: "5px",
                    }}
                    className="fa-solid fa-angle-left"
                  ></i>
                </button>
                <button id="">
                  <i
                    style={{
                      border: "1px solid #14494433",
                      padding: "11px",
                      color: "#144944",
                      borderRadius: "5px",
                    }}
                    className="fa-solid fa-angle-right"
                  ></i>
                </button>
                <button id="pagination-button">
                  <i
                    style={{
                      border: "1px solid #14494433",
                      padding: "11px",
                      color: "#144944",
                      borderRadius: "5px",
                    }}
                    className="fa-solid fa-angles-right"
                  ></i>
                </button>
              </div>
            </div> */}
          </div>
        </main>

        <div className="flex justify-center">
          <div id="demo" style={{ marginTop: "150px", height: "270px" }}>
            <h1 className="text-[#144944]">
              Transform Your Financial <br />
              Processes with Neo CFO
            </h1>
            <div className="w-full bg-red-500">
              <button
                className="flex -translate-y-4 items-center rounded-sm bg-white px-4 py-2 font-sans font-semibold text-[#144944]"
                style={{ fontSize: "12px" }}
              >
                SCHEDULE DEMO{" "}
                <GoArrowUpRight className="text-xs text-[#144944]" />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-64">
          <Footer />
        </div>
      </div>
    </>
  );
}
