import "../css/accountpayble.css";

import { useState } from "react";
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';

import { questions } from "../pages/contents/story-content";
import FadeInWrapper from "./FadeInWrapper";
import Footer from "./footer";
import Terms from "./Terms";



export default function VendorManagement() {

  const [openIndex, setOpenIndex] = useState(null);


  return (
    <>
      <div className="h-full w-full">
        <div className="flex w-full flex-col justify-between gap-5 bg-gradient-to-r from-[#d8fa8f] via-[#c7d98e8a] to-[#E4FFA9] pt-12 md:h-screen md:flex-row">
          <div className="mt-2 h-full w-[80%] p-2 md:mt-28 md:p-12">
            <FadeInWrapper direction="left">
              <p className="text-2xl text-primary md:text-3xl lg:text-5xl">
                Vendor Management Hub  –
                <i className="font-serif text-[1.2em]"> All Your Vendor Data in One Place
                </i>
              </p>
              <p className="mt-5 text-justify text-base text-gray-600">
                A centralized dashboard that provides deep insights into vendor KYC details, vendor’s compliance history, and previous payment records.
              </p>
            </FadeInWrapper>
          </div>
          <div className="md:mt-20">
            <FadeInWrapper direction="right">
              <img
                src="/vendormanagment.png"
                className="mx-auto w-[93%] md:w-full"
                alt=""
              />
            </FadeInWrapper>
          </div>
        </div>



        <FadeInWrapper>
          <div className="bg-[#f9f9f9bd] mt-44 p-10 md:p-20">
            <div>
              <p className="text-3xl text-center text-primary">Key Features</p>
            </div>

            {/* Main Grid Container */}
            <div className="flex flex-col md:flex-row items-center justify-center gap-6 w-full mt-10">

              {/* Left Content Box */}
              <div className="w-full md:w-1/3 bg-[#6a676761] rounded-2xl p-6 md:p-12 text-center md:text-left">
                <p className="text-xl text-primary font-bold">Vendor KYC Insights</p>
                <p className="text-sm mt-1">View PAN, GST, and other compliance details.</p>

                <p className="mt-10 text-xl text-primary font-bold">Invoice & Payment History</p>
                <p className="text-sm mt-1">View all past invoices, payment statuses, and outstanding dues.</p>
              </div>

              {/* Image Box */}
              <div className=" md:w-60 lg:w-80 rounded-2xl shadow-2xl">
                <img src="/vendor1.png" alt="Vendor Image" className="rounded-2xl w-full h-auto" />
              </div>

              {/* Right Content Box */}
              <div className="w-full md:w-1/3 bg-[#6a676761] rounded-2xl p-6 md:p-12 text-center md:text-left">
                <p className="text-xl text-primary font-bold">GST Compliance Monitoring</p>
                <p className="text-sm mt-1">Track vendor GST return filing history to ensure ITC eligibility.</p>
              </div>

            </div>
          </div>
        </FadeInWrapper >

        <FadeInWrapper direction="left">
          <div className="mt-32 md:mt-56 grid place-items-center  grid-cols-1 p-4 md:mx-20  ">
            <p className="md:text-4xl text-2xl text-primary">Vendor Dashboard Overview</p>
            <div className="h-full w-full mt-20">
              <img src="/NeoCFO[2].gif" alt="Animated GIF" className="w-[80%] object-fit mx-auto h-[100%] rounded-2xl" />

            </div>
            {/* <div className="mx-auto w-full rounded-2xl">
            <Carousel
              responsive={featureResponsive}
              arrows={true}
              showDots={screenSize === "mobile"}
              autoPlay
              infinite
              pauseOnHover
            >
              <img
                src="/vendorblur1.jpg"
                className="mx-auto  rounded-2xl object-cover p-8 md:w-[75%] md:p-12"
                alt="vendor 1"
              />
              <img
                src="/vendorblur2.jpg"
                className="mx-auto w-full rounded-2xl object-cover p-8 md:w-[75%] md:p-12"
                alt="vendor 2"
              />
              <img
                src="/vendorblur3.jpg"
                className="mx-auto w-full rounded-2xl object-cover p-8 md:w-[75%] md:p-12"
                alt="vendor 3"
              />
              <img
                src="/vendorblur4.jpg"
                className="mx-auto w-full rounded-2xl object-cover p-8 md:w-[75%] md:p-12"
                alt="vendor 4"
              />
            </Carousel>
          </div> */}
          </div>
        </FadeInWrapper>


        <div className="mt-56 grid place-items-center p-8  md:grid-cols-2">
          <FadeInWrapper direction="left">
            <div className="mx-auto w-full">
              <img
                src="/storyquestion.webp"
                className="mx-auto h-[70%] w-full rounded-lg object-cover p-8 md:w-[75%] md:p-12"
                alt="Invoice"
              />
            </div>
          </FadeInWrapper>
          <FadeInWrapper direction="right">
            <div className="">
              <p className="p-2 text-primary text-xl md:text-3xl md:w-[80%] mb-5">
                <i className="font-serif text-[.4em]"> </i> FAQs</p>
              {questions.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  className="w-full max-w-[500px] cursor-pointer rounded-lg m-4 bg-slate-100 p-4 hover:bg-slate-400 lg:p-3"
                  style={{
                    transitionDuration: "0.4s",
                    backgroundColor: `${openIndex === index ? "#F1FFE6" : ""}`,
                  }}
                >
                  <span className="flex items-center justify-between text-[16px]">
                    {item.question}{" "}
                    {openIndex === index ? (
                      <IoIosArrowUp className="text-sm text-green-800" />
                    ) : (
                      <IoIosArrowDown className="text-sm text-green-800" />
                    )}
                  </span>
                  <div
                    style={{
                      display: `${openIndex === index ? "block" : "none"}`,
                    }}
                    className="mt-1 text-[13px] text-slate-500"
                  >
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </FadeInWrapper>
        </div>

        {/* <div className="mt-2 grid place-items-center p-4 md:mx-20 md:mt-20 md:grid-cols-2">
          <div className="p-4 md:p-12">
            <p className="mb-5 text-3xl text-primary md:mb-6 md:mt-2">
              Lorem, ipsum dolor
            </p>
            <p className="text-justify text-sm text-[#3B3B3B] md:text-lg">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi,
              ea incidunt qui eos dolorem velit deserunt illo eveniet debitis
              quaerat porro aspernatur laborum repudiandae reiciendis rem
              facilis accusantium, minima tempore est esse ipsa sapiente beatae
              aut facere? Optio laudantium dignissimos quae tempore molestiae
              alias possimus iusto, error veritatis corrupti qui temporibus
              dolorum ducimus quo quaerat.
            </p>
          </div>
          <div className="mx-auto w-full">
            {" "}
            <img
              src="/image1.webp"
              className="mx-auto h-[55%] w-full rounded-lg object-cover p-8 md:w-[75%] md:p-12"
              alt="Invoice"
            />
          </div>
        </div> */}
      </div>
      {/* <FadeInWrapper direction="top"> */}
      <div className="mt-32">
        <FadeInWrapper direction="left">
        <Terms />
        </FadeInWrapper>
      </div>
      {/* </FadeInWrapper> */}
      <Footer />
    </>
  );
}
