import 'react-multi-carousel/lib/styles.css';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AccountsPayble from './components/accountsPaybles';
import Navbar from './components/header';
import ScrollToTop from './components/ScrollTop';
// import ItcManagement from './components/ItcManagement';
import VendorManagement from './components/VendorManagement';
import Blogpage from './pages/blogpage';
import Feature from './pages/feature';
import Home from './pages/home';
import Privacy from './pages/privacy';
import Read from './pages/readblog';
import Schedule from './pages/schedule';
import Story from './pages/story';
import Terms from './pages/terms';

function App() {
  const location = useLocation();

  const routesWithHeader = [
    '/schedule',
    '/story',
    '/feature',
    '/blogpage',
    '/privacy',
    '/terms',
    '/feature/accounts-payble',
    '/feature/vendor-management',
    // '/feature/itc-management'
  ];
  return (
    <div className="mx-auto w-full max-w-screen-3xl">
      {( location.pathname.startsWith('/read')|| routesWithHeader.includes(location.pathname) )&& <Navbar />}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/story" element={<Story />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/blogpage" element={<Blogpage />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/feature/accounts-payble" element={<AccountsPayble />} />
        <Route path="/feature/vendor-management" element={<VendorManagement />} />
        {/* <Route path="/feature/itc-management" element={<ItcManagement />} /> */}
        <Route path="/read/:blogId" element={<Read />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
