import PropTypes from "prop-types"; // Import PropTypes
import { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";

// Sample blog data 
export const homeBlogData = [
  {
    id: 1,
    image: "blog1.jpg",
    text: "Accounts Payable Invoice Scanning Automation with Neo CFO",
    readTime: 5,
  },
  {
    id: 2,
    image: "Blog2.webp",
    text: "The Future of Finance is AI: How to Leverage it Today",
    readTime: 5,
  },
  {
    id: 3,
    image: "blog3.png",
    text: "How AP Automation Transforms Annual Audits",
    readTime: 5,
  },
  {
    id: 4,
    image: "blog4.png",
    text: "Revolutionize Your Accounts Payable: Seamless Automation with Zoho Books and Neo CFO ",
    readTime: 5,
  },
  {
    id: 5,
    image: "blog5.jpg",
    text: "Essential KPIs to Track for Accounts Payable Teams: Optimizing Financial Performance",
    readTime: 5,
  },
];

const HomeBlogCard = ({ image, text, readTime, onClick }) => (
  <div
    className="relative w-full h-[300px] rounded-xl hover:rounded-xl transition-all duration-300 hover:scale-105 hover:cursor-pointer"
    onClick={onClick} // Click event added
  >
    <img
      src={"/" + image}
      alt={text}
      className="absolute inset-0 h-full w-full object-cover rounded-xl hover:rounded-xl"
    />
    <div className="absolute hover:rounded-xl right-6 top-6 flex items-center justify-center w-10 h-10 rounded-full bg-black/30 text-2xl text-white backdrop-blur-lg">
      <GoArrowUpRight />
    </div>
    <div className="absolute bottom-0 left-0 w-full rounded-xl bg-gradient-to-t hover:rounded-xl from-black/70 to-transparent p-3 pt-10 backdrop-blur-[1px]">
      <h4 className="text-lg text-white max-w-[27ch] ">{text}</h4>
      <p className="text-sm font-semibold text-gray-300">{readTime} min read</p>
    </div>
  </div>
);

// Define PropTypes for HomeBlogCard
HomeBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  readTime: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const BlogCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const extendedData = [...homeBlogData, ...homeBlogData];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === homeBlogData.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-[100%] mx-auto p-2">
      <div className="overflow-hidden">
        <div
          className="flex gap-6 transition-transform duration-1000"
          style={{
            transform: `translateX(-${(currentIndex % homeBlogData.length) * (100 / 3)}%)`,
          }}
        >
          {extendedData.map((blog, index) => (
            <div
              key={blog.id + index}
              className="w-full sm:w-[50%] md:w-[33.333%] flex-shrink-0"
              style={{ transition: "opacity 1s ease" }}
            >
              <HomeBlogCard
                image={blog.image}
                text={blog.text}
                readTime={blog.readTime}
                onClick={() => navigate(`/read/${blog.id}`)} // Navigate to the blog page
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
