export const faqData = [
  {
    question: 'What is NEO CFO?',
    answer:
      'NEO CFO is a comprehensive finance automation platform designed to streamline and automate your financial processes. From managing automation of accounting entries and GST taxation to handling procure-to-pay processes, NEO CFO helps you optimize and simplify your day-to-day financial tasks, freeing up to 80% of your time.',
  },
  {
    question: 'Is my financial information secure?',
    answer:
      'Yes, at NEO CFO, we prioritize your data security. We use advanced encryption methods and security protocols to ensure your financial data is protected and confidential and comply with industry-standard security practices to ensure your financial information is protected. Regular security audits and updates are conducted to maintain the highest level of security.',
  },
  {
    question: 'What services does NEO CFO offer?',
    answer:
      'NEO CFO offers a wide range of services, including, but not limited to: Automated accounting entries, GST taxation management, Procure-to-pay process automation, Financial data integration, Predictive analytics for P&L and cash flow outcomes, Expense categorization and management, Reconciliation of GST input with accounting records.',
  },
  {
    question: 'Can I integrate other financial tools with the platform?',
    answer:
      'Yes, NEO CFO supports integration with various financial tools and platforms. You can easily connect your accounting software (i.e., Tally or Zoho Books), GST portal, and other financial services/bank accounts to streamline your workflows and ensure all your data is centralized in one place.',
  },
  {
    question: 'How does NEO CFO handle GST compliance?',
    answer:
      'NEO CFO automates GST compliance by integrating with the GST portal and providing tools for managing GST returns, reconciling GST input with accounting records, and ensuring accurate GST calculations. This helps you stay compliant with the latest GST regulations effortlessly.',
  },
  {
    question: 'Can multiple users access the same NEO CFO account?',
    answer:
      'Yes, NEO CFO allows multiple users to access the same account. You can add team members and assign different roles and permissions based on their responsibilities. This facilitates collaboration and ensures that everyone on your team can contribute effectively.',
  },
];


export const questions = [
  {
    question: 'How does Neo CFO track vendor GST compliance? ',
    answer:
      "We fetch real-time GST return filing data to flag vendors who haven't filed GST returns, affecting ITC claims. ",
  },
  {
    question: 'Does the platform support bulk vendor onboarding?',
    answer:
      'Yes, you can upload vendor details in bulk, and Neo CFO auto-verifies PAN and GST details.',
  },
  {
    question: 'Can I see all invoices and payments related to a vendor?',
    answer:
      'Absolutely! The dashboard provides a full financial history, including invoices, payments, and pending dues.',
  },
  {
    question: 'Is vendor data updated in real-time?',
    answer:
      ' Yes, vendor compliance and transaction details are continuously updated to reflect the latest status.',
  },

];

export const accountPaybleQuestions = [
  {
    question: 'How accurate is the AI-powered invoice scanning? ',
    answer:
      'Neo CFO’s AI achieves 95%+ accuracy in extracting invoice details, reducing manual errors and saving time. ',
  },
  {
    question: 'Can we set approval workflows for invoice payments? ',
    answer:
      'Yes, you can configure multi-tier approval workflows based on invoice amount, vendor category, or other custom rules.',
  },
  {
    question: 'Does this integrate with our existing accounting software? ',
    answer:
      ' Absolutely! Neo CFO syncs seamlessly with Tally, Zoho Books, and other leading accounting platforms.',
  },
  {
    question: 'Can Neo CFO handle different invoice formats? ',
    answer:
      'Yes, it supports PDFs, scanned images, and structured invoice formats, ensuring flexibility. ',
  },
  {
    question: 'How does the system categorize expenses automatically? ',
    answer:
      'The AI recognizes patterns in vendor names, invoice descriptions, and past entries to auto-assign the correct ledger. ',
  },

];


export const GstItcFaqs = [
  {
    question: 'How does Neo CFO detect mismatches in GST input credit? ',
    answer:
      'Our AI model compares invoice data with auto fetched data from your GSTR-2A/2B, flagging any inconsistencies in tax amounts or vendor filing status. ',
  },
  {
    question: 'What happens if a vendor hasn’t filed their GST returns? ',
    answer:
      'The system categorizes such invoices under ‘Hold GST Amount’ and suggests corrective actions. ',
  },
  {
    question: 'Can I adjust GST mismatches automatically?',
    answer:
      'Yes, Neo CFO suggests necessary tax adjustments and automates reconciliation process. ',
  },
  {
    question: 'Does Neo CFO integrate with GST portals for real-time updates? ',
    answer:
      'Yes, we pull data directly from GSTN, ensuring your input credit is always up-to-date.',
  },
  {
    question: 'Can I generate reports to track pending GST credits?',
    answer:
      'Absolutely! Neo CFO provides detailed reports on pending, matched, and disputed credits for better tax planning. ',
  },

];  