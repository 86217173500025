import "../css/accountpayble.css";

import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import Carousel from "react-multi-carousel";

// import FadeInWrapper from '../components/FadeInWrapper';
import useIsMobile from "../hooks/isMobile";
import { featuresCard } from "../pages/contents/home-content";
import { accountPaybleQuestions } from "../pages/contents/story-content";
import AccountpaybleCards from "./AccountpaybleCards"
import FadeInWrapper from "./FadeInWrapper";
import Footer from "./footer";
import Terms from "./Terms"

const featureResponsive = {
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 512 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 512, min: 0 },
    items: 1,
  },
};
export default function AccountsPayble() {
  const [openIndex, setOpenIndex] = useState(null);
  const screenSize = useIsMobile();

  const scrollerRef = useRef(null);

  useEffect(() => {
    const scroller = scrollerRef.current;
    if (!scroller) return;

    const scrollerInner = scroller.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);
    const totalContentWidth = scrollerInner.scrollWidth;

    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem);
    });

    let scrollPosition = 0;
    let animationId;

    const animate = () => {
      scrollPosition += 0.8;

      if (scrollPosition >= totalContentWidth / 2) {
        scrollPosition = scrollPosition % (totalContentWidth / 2);
      }

      scrollerInner.style.transform = `translate3d(-${scrollPosition}px, 0, 0)`;
      animationId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, []);
  return (
    <>
      <div className="h-full w-full">
        <div className="bg-gradient-to-r pt-28 from-[#d8fa8f] via-[#c7d98e8a] to-[#E4FFA9]">
          <FadeInWrapper direction="left">
          <div className="md:mx-auto max-w-[45rem] text-center text-4xl mx-8">
            <i className="font-serif text-[1.2em]">
              {" "}
              Automated Accounts Payable
            </i>
            <p className=" mt-2 text-3xl text-[#3B3B3B] md:text-5xl">
              Streamline Invoice Processing with AI

            </p>
            <p className="p-3 mt-2 text-base text-gray-700 text-center">
              Say goodbye to manual data entry! Simply upload invoices, and Neo CFO’s AI-powered engine extracts and populates key details instantly.
            </p>
          </div>
          </FadeInWrapper>
          <div>
            <FadeInWrapper>
            <div className="relative h-[460px]">
              <div className="absolute top-1/2 w-full -translate-y-1/2 p-5">

                <Carousel
                  responsive={featureResponsive}
                  arrows={false}
                  showDots={screenSize === "mobile"}
                  autoPlay
                  infinite
                  pauseOnHover
                >
                  {featuresCard.map((card) => (
                    <div
                      key={card.id}
                      className="relative mx-auto h-[15rem] w-[80%] overflow-hidden rounded-2xl bg-white/50 p-8 backdrop-blur"
                    >
                      <p className="text-[20px] text-green-900">{card.title}</p>
                      <p className="mt-4 text-sm text-[12px] ">
                        {card.text}
                      </p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            </FadeInWrapper>
          </div>
        </div>
        {/* <div>
          <p className="text-center text-4xl mb-8 mt-32  text-primary">Our <i className="font-serif text-[1.2em]">
            trusted Brands
          </i> </p>
          <div className="scrollerBar" data-speed="fast" ref={scrollerRef}>
            <ul className="tag-list scroller__inner">
              <li><i className="font-serif text-[1.2em]">Lorem1</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem2</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem3</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem4</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem5</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem6</i></li>
              <li><i className="font-serif text-[1.2em]">Lorem7</i></li>
            </ul>
          </div>
        </div> */}

        {/* <div className="grid md:grid-cols-2 p-8 mt-28 md:mt-40 place-items-center">
          <div className=""> <img
            src="/invoice.webp"
            className=" w-[85%] h-[45%] md:p-12 object-cover rounded-lg"
            alt="Invoice"
          /></div>
          <div className="md:p-12 p-4">
            <p className=" text-4xl text-primary mt-10 md:mt-2 md:mb-8 mb-5">How it works</p>
            <p className="text-[#3B3B3B] md:text-xl text-sm text-justify">Show AI detecting key invoice details in real time. </p>
          </div>
        </div> */}
        <FadeInWrapper direction="left">
        <div className="">
          <AccountpaybleCards />
        </div>
        </FadeInWrapper>
        {/* <div className="grid md:grid-cols-2 p-8 mt-12 md:mt-40 place-items-center">
          <div className="md:p-12 p-4">
            <p className=" text-3xl text-primary  md:mt-2 md:mb-6 mb-5">How it works</p>
            <p className="text-[#3B3B3B] md:text-lg text-sm text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, ea incidunt qui eos dolorem velit deserunt illo eveniet debitis quaerat porro aspernatur laborum repudiandae reiciendis rem facilis accusantium, minima tempore est esse ipsa sapiente beatae aut facere? Optio laudantium dignissimos quae tempore molestiae alias possimus iusto, error veritatis corrupti qui temporibus dolorum ducimus quo quaerat.</p>
            <p className="mt-12 text-xl text-primary font-bold">Real-Time Expense Tracking</p>
            <p className="text-base md:text-lg mt-2">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, nesciunt.</p>
            <p className="mt-5 text-xl text-primary font-bold">Best-in-Industry Integration</p>
            <p className="text-base md:text-lg mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis repellat nobis blanditiis dolor ad!</p>
          </div>
          <div className="w-full mx-auto"> <img
            src="/image1.webp"
            className=" md:w-[85%] mx-auto w-full h-[55%] p-8 md:p-12 object-cover rounded-lg"
            alt="Invoice"
          /></div>
        </div> */}

        <div className=" mt-44 grid place-items-center p-8  md:grid-cols-2">
        <FadeInWrapper direction="left">
          <div className="mx-auto w-full">
            <img
              src="/storyquestion.webp"
              className="mx-auto h-[70%] w-full rounded-lg object-cover p-8 md:w-[75%] md:p-12"
              alt="Invoice"
            />
          </div>
          </FadeInWrapper>
          <FadeInWrapper direction="right">
          <div className="">
            <p className="p-2 text-primary text-xl md:text-3xl md:w-[80%] mb-5">
              <i className="font-serif text-[.4em]"> </i> FAQs</p>
            {accountPaybleQuestions.map((item, index) => (
              <div
                key={index}
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full max-w-[500px] cursor-pointer rounded-lg m-4 bg-slate-100 p-4 hover:bg-slate-400 lg:p-3"
                style={{
                  transitionDuration: "0.4s",
                  backgroundColor: `${openIndex === index ? "#F1FFE6" : ""}`,
                }}
              >
                <span className="flex items-center justify-between text-[16px]">
                  {item.question}{" "}
                  {openIndex === index ? (
                    <IoIosArrowUp className="text-sm text-green-800" />
                  ) : (
                    <IoIosArrowDown className="text-sm text-green-800" />
                  )}
                </span>
                <div
                  style={{
                    display: `${openIndex === index ? "block" : "none"}`,
                  }}
                  className="mt-1 text-[13px] text-slate-500"
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </FadeInWrapper>
        </div>
      </div>
      {/* <FadeInWrapper direction="top"> */}
      <div className="mt-32">
      <FadeInWrapper direction="left">
      <Terms />
      </FadeInWrapper>
      </div>
      {/* </FadeInWrapper> */}
      <Footer />
    </>
  );
}
